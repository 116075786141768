export default {
  data() {
    return {
      direction: "bottom",
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: false,
      bottom: false,
      left: false,
      transition: "slide-y-reverse-transition",
    };
  },
  methods: {
    changeLanguage(val) {
      this.$store.commit("toggle_language", val);
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    }
    },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
};